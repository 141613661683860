@import "https://fonts.googleapis.com/css?family=Open+Sans";
body {
  background: #f2f2f2;
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

::selection, ::-moz-selection {
  color: initial;
  background: initial;
}

.application {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.application header {
  width: 100%;
  background: #fff;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 5px;
  display: flex;
}

.application header .container {
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.application header .container img {
  height: 100px;
  width: 100px;
}

.application header .container > span {
  pointer-events: none;
  flex-grow: 1;
  padding-left: 10px;
  font-size: 24px;
  font-weight: bold;
}

.application header .container .links a {
  color: #000;
  padding-right: 15px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.application header .container .links a:hover {
  text-decoration: underline;
}

.bread-crumbs {
  width: 100%;
  color: #000;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.bread-crumbs a {
  color: #000;
  text-decoration: none;
}

.bread-crumbs a:hover {
  text-decoration: underline;
}

.bread-crumbs a:last-child {
  pointer-events: none;
  font-weight: normal;
}

.bread-crumbs img {
  max-height: 24px;
  max-width: 24px;
}

.items {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0;
  display: flex;
}

.items .container {
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 90px;
  padding: 15px;
  display: flex;
}

.items .container .header {
  width: 100%;
  flex-wrap: wrap;
  padding: 5px 5px 15px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

.items .container .header .name {
  flex-grow: 1;
  padding-left: 35px;
}

.items .container .header .size {
  text-align: right;
  padding-right: 15px;
}

.items .container .header .date {
  width: 85px;
  text-align: right;
  padding-right: 15px;
}

.items .container .header .download {
  width: 70px;
  text-align: center;
  padding-right: 15px;
}

.items .container .header .copy {
  width: 35px;
  text-align: left;
  padding-right: 15px;
}

.items .container.hide .header {
  display: none;
}

.items .container .error {
  height: 100px;
  width: 100px;
  opacity: .5;
  margin-top: 100px;
}

.item {
  width: 100%;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-bottom: none;
  align-items: center;
  padding: 5px;
  display: flex;
}

.item:nth-child(2) {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.item:last-child {
  border-bottom: 1px solid #d2d2d2;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.item .icon {
  width: 30px;
  height: 30px;
  opacity: .5;
  background-image: url("file.14bf4f2f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.item .icon.folder {
  background-image: url("folder.b49ffe5e.svg");
}

.item .name {
  flex-grow: 1;
  padding-left: 5px;
  font-size: 12px;
}

.item .size {
  opacity: .75;
  padding-right: 15px;
  font-size: 12px;
}

.item .date {
  width: 85px;
  text-align: right;
  opacity: .75;
  padding-right: 15px;
  font-size: 12px;
}

.item .download {
  width: 70px;
  text-align: center;
  opacity: .75;
  cursor: pointer;
  text-transform: uppercase;
  color: #000;
  padding-right: 15px;
  font-size: 12px;
  font-weight: bold;
}

.item .download:hover {
  color: #1976d2;
}

.item .copy {
  width: 35px;
  text-align: left;
  opacity: .75;
  cursor: pointer;
  text-transform: uppercase;
  color: #000;
  padding-right: 15px;
  font-size: 12px;
  font-weight: bold;
}

.item .copy:hover {
  color: #1976d2;
}

.item.folder:hover {
  color: #fff;
  cursor: pointer;
  background: #4284f4;
}

.item.folder:hover * {
  opacity: 1;
}

.item.folder:hover .icon {
  filter: brightness(0) invert();
}

/*# sourceMappingURL=index.4378183e.css.map */
