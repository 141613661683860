.bread-crumbs {
	width: 100%;
	padding: $spacing-unit calc( $spacing-unit * 2 / 3 );
	font-size: 16px;
	color: #000;
	font-weight: bold;
	display: flex;
	a {
		color: #000;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&:last-child {
			font-weight: normal;
			pointer-events: none;
		}
	}
	img {
		max-height: 24px;
		max-width: 24px;
	}
}
