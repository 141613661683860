.items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	padding: calc( $spacing-unit * 4 ) 0;
	.container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: $screen-max-width;
		margin-bottom: calc( $spacing-unit * 6 );
		padding: $spacing-unit;
		justify-content: center;
		.header {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding: calc( $spacing-unit / 3 );
			padding-bottom: $spacing-unit;
			font-size: 12px;
			font-weight: bold;
			.name {
				padding-left: calc( 30px + ( $spacing-unit / 3 ) );
				flex-grow: 1;
			}
			.size {
				padding-right: $spacing-unit;
				text-align: right;
			}
			.date {
				width: 85px;
				padding-right: $spacing-unit;
				text-align: right;
			}
			.download {
				width: 70px;
				padding-right: $spacing-unit;
				text-align: center;
			}
			.copy {
				width: 35px;
				padding-right: $spacing-unit;
				text-align: left;
			}
		}
		&.hide {
			.header {
				display: none;
			}
		}
		.error {
			height: 100px;
			width: 100px;
			opacity: 0.5;
			margin-top: 100px;
		}
	}
}
