.item {
	display: flex;
	width: 100%;
	background: #FFF;
	border: solid 1px $color-border;
	border-bottom: none;
	align-items: center;
	padding: calc( $spacing-unit / 3 );
	&:nth-child( 2 ) {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}
	&:last-child {
		border-bottom: solid 1px $color-border;
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
	}
	.icon {
		width: 30px;
		height: 30px;
		background-image: url("images/file.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 24px;
		opacity: 0.5;
		&.folder {
			background-image: url("images/folder.svg");
		}
	}
	.name {
		padding-left: calc( $spacing-unit / 3 );
		font-size: 12px;
		flex-grow: 1;
	}
	.size {
		padding-right: $spacing-unit;
		font-size: 12px;
		opacity: 0.75;
	}
	.date {
		padding-right: $spacing-unit;
		width: 85px;
		text-align: right;
		font-size: 12px;
		opacity: 0.75;
	}
	.download {
		padding-right: $spacing-unit;
		width: 70px;
		text-align: center;
		font-size: 12px;
		opacity: 0.75;
		cursor: pointer;
		text-transform: uppercase;
		color: #000;
		font-weight: bold;
		&:hover {
			color: $color-link;
		}
	}
	.copy {
		padding-right: $spacing-unit;
		width: 35px;
		text-align: left;
		font-size: 12px;
		opacity: 0.75;
		cursor: pointer;
		text-transform: uppercase;
		color: #000;
		font-weight: bold;
		&:hover {
			color: $color-link;
		}
	}
	&.folder:hover {
		background: $color-select;
		color: #FFF;
		cursor: pointer;
		* {
			opacity: 1;
		}
		.icon {
			filter: brightness(0) invert(1);
		}
	}
}
