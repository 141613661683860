.application {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	header {
		padding: $spacing-unit calc( $spacing-unit / 3 );
		width: 100%;
		background: #FFF;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.container {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: $screen-max-width;
			img {
				height: 100px;
				width: 100px;
			}
			> span {
				padding-left: calc( $spacing-unit * 2 / 3 );
				flex-grow: 1;
				font-size: 24px;
				font-weight: bold;
				pointer-events: none;
			}
			.links {
				a {
					padding-right: $spacing-unit;
					text-decoration: none;
					color: #000;
					font-weight: bold;
					font-size: 18px;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
